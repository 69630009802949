import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";
import {
  detailSmall,
  heading100,
  heading150,
  heading400,
  paragraphTextLarge,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import Button from "../shared/Button/Button";

import ElementLogo from "./assets/element_logo.inline.png";
import UniversityOfOxfordLogo from "./assets/university-of-oxford_logo.inline.png";
import AirLiquideLogo from "./assets/air-liquide_logo.inline.png";
import NanyangLogo from "./assets/nanyang_logo.inline.png";
import OvakoLogo from "./assets/ovako_logo.inline.png";
import WeldingAlloysLogo from "./assets/welding-alloys_logo.inline.png";
import AstmLogo from "./assets/astm_logo.inline.png";
import LeonardoLogo from "./assets/leonardo-logo.jpg";
import BabcocLogo from "./assets/babcoc-logo-web-scaled.jpg";
import DeepLogo from "./assets/deep_logo.inline.png";
import FiuLogo from "./assets/fiu_logo.inline.png";
import MaterialsLogo from "./assets/materials-processing-institute_logo.inline.png";
import Morf3dLogo from "./assets/morf3d_logo.inline.png";
import NasaLogo from "./assets/nasa_logo.inline.png";
import NplLogo from "./assets/npl_logo.inline.png";
import NikongLogo from "./assets/nikon_logo.inline.png";
import RenishawLogo from "./assets/renishaw_logo.inline.png";
import ZeissLogo from "./assets/zeiss_logo.inline.png";
import AirbusLogo from "./assets/airbus-logo_logo.inline.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Swiper as SwiperClass, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";

const future = <span>future</span>;
const title = <>Meet the {future} of{"\n"}mechanical testing</>;
const byline = `We combine testing hardware with\nadvanced software solutions to create\nnovel mechanical testing systems.`;
const desktopButtonDemo = `Book demo`;
const desktopButtonQuote = `Request a quote`;

const trustedBy = [
  { key: "leonardo", image: LeonardoLogo },
  { key: "babcoc", image: BabcocLogo },
  { key: "nasa", image: NasaLogo, width: "75%"  },
  { key: "astmv", image: AstmLogo },
  { key: "oxford", image: UniversityOfOxfordLogo, width: "95%" },
  { key: "npl", image: NplLogo },
  //{ key: "deep", image: DeepLogo },
  { key: "renishaw", image: RenishawLogo },
  { key: "AirbusLogo", image: AirbusLogo },
  { key: "ovako", image: OvakoLogo },
  { key: "fiu", image: FiuLogo },
  { key: "AirLiquideLogo", image: AirLiquideLogo },
  //{ key: "welding", image: WeldingAlloysLogo, width: "95%" },
  { key: "element", image: ElementLogo },
  { key: "deep", image: DeepLogo },
  //{ key: "mpi", image: MaterialsLogo },
  //{ key: "morf3d", image: Morf3dLogo },
  { key: "zeiss", image: ZeissLogo },
  //{ key: "nanyang", image: NanyangLogo },
  { key: "nikon", image: NikongLogo },

];

const IndexHero = () => {
  const data = useStaticQuery(graphql`
    query {
      render: file(relativePath: { eq: "plastometrex_render.png" }) {
        childImageSharp {
          gatsbyImageData(width: 1300, placeholder: TRACED_SVG,)
        }
      }
    }
  `);

  const ImageGrid = () => {
    return (
      <GridContainer>
        {trustedBy.map((item, i) => (
          <ImageWrapper key={i}>
            <img src={item.image} alt={`Slide ${i}`} width={item.width || "100%"} />
          </ImageWrapper>
        ))}
      </GridContainer>
    );
  };

  const image = getImage(data.render);

  return (
    <Container>
      <TopContentContainer>
        <TopImageWrapper>
          {image && (
            <GatsbyImage
              image={image}
              alt="PLX-Benchtop - stress/strain curves from indentation mechanical tests"
              loading="eager"
            />
          )}
        </TopImageWrapper>
        <RenderWrapper>
          <Title>{title}</Title>
        </RenderWrapper>
        <TextContent>
          <Byline>{byline}</Byline>
          <DesktopButtons>
            <Link to="/book-a-demo">
              <Button size="medium" tier="primary">
                {desktopButtonDemo}
              </Button>
            </Link>
            <Link to="/request-a-quote">
              <Button size="medium" tier="secondary" arrow>
                {desktopButtonQuote}
              </Button>
            </Link>
          </DesktopButtons>
        </TextContent>
      </TopContentContainer>
      <MobileContainer>
        <TrustedByContainer>
        <TitleTrustedBy>Trusted by the best</TitleTrustedBy>
        <StyledSwiper
          slidesPerView={5}
          navigation={false}
          modules={[Navigation, Autoplay]}
          preloadImages={true}
          spaceBetween={64}
          loop={true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          breakpoints={{
            300: {
              slidesPerView: 3,
              spaceBetween: 0,
            },

            640: {
              slidesPerView: 3,
              spaceBetween: 32,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 64,
            },
          }}
        >
          {trustedBy.map((item, i) => {
            return (
              <SwiperSlide key={i}>
                <ImageWrapper ><img src={item.image} width={item.width ? item.width : "100%"} /></ImageWrapper>
              </SwiperSlide>
            )
          })}
        </StyledSwiper>
      </TrustedByContainer>
      </MobileContainer>
      <DesktopContainer> <ImageGrid/> </DesktopContainer>
    </Container>
  );
};

export default IndexHero;

const GridContainer = styled("div", {
  width: "90%",
  margin: "auto",
  maxWidth: 1160,
  display: 'grid',
  gap: '10px',
  gridTemplateColumns: 'repeat(auto-fill, minmax(150px, 1fr))', 

  
  '@media (min-width: 768px)': {
    gridTemplateColumns: 'repeat(5, 1fr)', 
    gridTemplateRows: 'repeat(3, 1fr)',
  }
});

const Container = styled("div", {
  width: "90%",
  margin: "auto",
  maxWidth: 1377,
  marginTop: 20,
  "@lg": {
    marginBottom: 50,
    paddingBottom: 20,
    marginTop: 0,
  },
});

const TopContentContainer = styled("div", {
  background: "#f9f9f9",
  borderRadius: "6px",
  display: "grid",
  gridTemplateColumns: "repeat(1fr)",
  maxHeight: "calc(100vh-64px)",
  "@md": {
    paddingBottom: 64,
    gridTemplateColumns: "repeat(10, 1fr)",
    maxHeight: "calc(100vh-64px)",
    aspectRatio: "1241/586",
    background: "linear-gradient(180deg, #F9F9F9 0%, rgba(231, 231, 231, 0.83) 100%)",
  },
});

const TopImageWrapper = styled("div", {
  marginBottom: 20,
  textAlign: "center",
  gridRow: "3 / span 1",
  gridColumn: "span 1",
  height: "auto",
  ".gatsby-image-wrapper img": {
    objectFit: "contain!important"
  },
  "@md": {
    gridRow: "1 / span 1",
    gridColumn: "1 / span 10",
    height: "59vh",
    div: {
      height: "57vh",
    },
  },
});

const TextContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  gridRow: "2 / span 1",
  gridColumn: "span 1",
  "button": { whiteSpace: "pre" },
  "@md": {
    gridRow: "2 / span 1",
    gridColumn: "7 / span 3",
  },
});

const RenderWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  gridRow: "1 / span 1",
  gridColumn: "1 / span 1",
  "@md": {
    gridRow: "2 / span 1",
    gridColumn: "2 / span 4",
  },
});

const Title = styled("h1", {
  ...heading150,
  fontWeight: 700,
  fontSize: 24,
  marginTop: 10,
  marginBottom: 0,
  textAlign: "center",
  letterSpacing: "-0.4px",
  whiteSpace: "pre-line",
  span: {
    color: "inherit",
    fontSize: "inherit",
    lineHeight: "inherit",
    fontWeight: "inherit",
    letterSpacing: "-0.9px",
    "@md": {
      color: "$blue100",
      letterSpacing: "-1px",
    },
  },
  "@md": {
    ...heading400,
    fontSize: 48,
    lineHeight: "57px",
    fontWeight: 700,
    textAlign: "left",
    marginTop: 20,
    marginBottom: 64,
    letterSpacing: "-1px",
  },
  "@media (min-width: 800px) and (max-width: 1046px)": {
    fontSize: "34px",
  },
  "@media (min-width: 1046px) and (max-width: 1281px)": {
    fontSize: "42px",
  },

});

const Byline = styled("p", {
  ...detailSmall,
  textAlign: "center",
  maxWidth: 468,
  marginBottom: 20,
  marginTop: 10,
  marginLeft: "auto",
  marginRight: "auto",
  fontSize: 15,
  whiteSpace: "pre-line",

  "@md": {
    ...paragraphTextLarge,
    marginBottom: 32,
    textAlign: "left",
    marginLeft: 0,
    marginRight: 0,
    whiteSpace: "initial",
  },
  "@media (min-width: 800px) and (max-width: 960px)": {
    fontSize: "15px",
  },
  "@media (min-width: 960px) and (max-width: 1281px)": {
    fontSize: "16px",
  },
});

const TitleTrustedBy = styled("h1", {
  ...heading100,
  margin: "0 auto",
  marginTop: 20,
  fontSize: 16,
  "@md": { display: "none" }
});

const TrustedByContainer = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "space-between",
  marginBottom: 0,
  borderBottom: "1px solid rgba(140,152,164,0.3)",
  "@md": {
    flexWrap: "nowrap",
    margin: "0 auto",
    maxWidth: 1100,
  },
  ".swiper-horizontal": { width: "100%" }
});

const ImageWrapper = styled("div", {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginBottom: 0,
  aspectRatio: "25 / 10",
  width: "50%",
  margin: "0 auto",
  "@md": {
    margin: "0px auto",
    width: "65%",
    aspectRatio: "1 / 1",
  }
});

const MobileButton = styled("div", {
  display: "flex",
  justifyContent: "center",
  "@md": { display: "none" },
});
const DesktopButtons = styled("div", {
  display: "none",
  "@md": { display: "flex" },
});

const StyledSwiper = styled(Swiper, {
  maxWidth: 1400,
  margin: "auto",
  '.swiper-wrapper': {alignItems: "center"},
});

const MobileContainer = styled("div", {
  display: "block",
  "@md": { display: "none" },
});
const DesktopContainer = styled("div", {
  display: "none",
  "@md": { display: "block" },
});